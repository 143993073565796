const BillingMenu = [
  {
    heading: "menu",
    route: "/seip-bill-info",
    pages: [
      {
        sectionTitle: "(Claim-1)",
        fontIcon: "fa-search",
        sub: [
          {
            heading: "Submitted Bills",
            route: "/seip-bill-info/claim-1-submitted-bills",
          },
          {
            heading: "Approved Bill ",
            route: "/seip-bill-info/claim-1-approved-bills",
          },
        ],
      },
      {
        sectionTitle: "(Claim-2)",
        route: "/training-partners-info/training-certificate",
        fontIcon: "fa-certificate",
        sub: [
          {
            heading: "Submitted Bills",
            route: "/seip-bill-info/claim-2-submitted-bills",
          },
          {
            heading: "Approved Bill ",
            route: "/seip-bill-info/claim-2-approved-bills",
          },
        ],
      },
      {
        sectionTitle: "(Claim-3)",
        route: "/training-partners-info/training-certificate-Information",
        fontIcon: "fa-info",
        sub: [
          {
            heading: "Submitted Bills",
            route: "/seip-bill-info/claim-3-submitted-bills",
          },
          {
            heading: "Approved Bill ",
            route: "/seip-bill-info/claim-3-approved-bills",
          },
        ],
      },
      {
        heading: "Advanced Bill Adjustment",
        route: "/seip-bill-info/manual-bill-adjustment",
        fontIcon: "fa-info",
      },
     
    ],
  },
];

export default BillingMenu;
